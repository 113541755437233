
fieldset.documents {
  padding: 0.5em;

  legend {
    padding-right: 10px;
  }
  &.required {
    background: url(/dist/images/required.png) no-repeat right;
  }
}

div.uploadWidget {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
  background-color: rgba(204, 204, 204, 0.10);
}

div.uploadWidget input[type=file] {
  padding-bottom: 5px;
}

.drop-area {
  height: 80px;
  text-align: center;
  border: 2px dashed #c0c0c0;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  background-color: rgba(204, 204, 204, 0.25);
}

.drop-area .drop-instructions {
  font-size: 1.3em;
  display: block;
  height: 30px;
}

.drop-area .drop-over {
  display: none;
  font-size: 2em;
  height: 30px;
}

.drop-area.over {
  background: #ffffa2;
  border: 2px dashed #000;
}

.drop-area.over .drop-instructions {
  display: none;
}

.drop-area.over .drop-over {
  display: block;
}

.drop-area.over .drop-over {
  display: block;
  font-size: 25px;
}

table.file-table {
  margin-top: 10px;
  width: 100%;
}

table.file-table .progress-bar-container {
  width: 100px;
  height: 10px;
}

table.file-table .progress-bar-container.uploaded {
  height: auto;
  border: none;
}

table.file-table progress {
  width: 100%;
}

table.file-table td {
  word-wrap: break-word;
}

.myGrid {
  width: 500px;
  height: 250px;
}

div.progress-bar-container a {
  padding-right: 5px;
}

table.report-detail {
  max-width: 98%;

  tr {
    vertical-align: top;
  }

  td {
    word-wrap: break-word;
    max-width: 20em;

    &.number {
      min-width: 8em;
      text-align: right;
    }

    &.document-type {
      min-width: 20em;
    }

    &.extra-info {
      min-width: 10em;

      fieldset {
        margin: 0;
        padding: 0;
      }

      label {
        margin: 0 2px 0 5px;
      }

      input[type="radio"] {
        margin: 0 5px 0 0;
      }

    }

    &.filename {

    }

    &.content-type {

    }

    &.notes {

    }

  }

}

ul.receipt {
  li {
    word-wrap: break-word;
    max-width: 40em;
  }
}
