@import "settings";
// Import foundation components from jspm
@import "jspm:foundation-sites/scss/foundation";

//@include foundation-everything;

@include foundation-global-styles;

//@include foundation-grid;
@include foundation-flex-grid;
@include foundation-flex-classes;

@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
//@include foundation-accordion-menu;
@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
//@include foundation-flex-video;
@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
@include foundation-sticky;
//@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

//@include motion-ui-transitions;
//@include motion-ui-animations;

.row {
    max-width: 100%;
}

//  TODO: Taken from demo makes bar black
$menu-background: #333;

.title-bar {
    background: $menu-background;
}

.top-bar {
    background: $menu-background;
    font-size: 0.8125rem;
    font-weight: bold;

    .menu {
        background: $menu-background;
        padding: 0;
    }

    figure.logo {
        margin-left: 10px;

        img {
            margin-top: -5px;
        }

        figcaption {
            display: inline-block;
            margin-top: 14px;
        }
    }

    ul {
        background: $menu-background;

        li {
            background: $menu-background;

            &.menu-text {
                color: #fff;
                padding: 0;

                &.info {
                    padding-left: 40px;
                    padding-top: 14px;
                    border: 0;
                }
            }

            a {
                color: #fff;
            }
        }
    }
}

// Bug fix
.input-group-button a {
    padding-top: 9px;
}

fieldset {
    border: 1px solid #ddd;
    margin: 1.125rem 0;
    padding: 1.25rem;

    legend {
        font-weight: bold;
        margin: 0;
        margin-left: -.1875rem;
        padding: 0 .1875rem;
    }
}

@media only screen and (min-width: 40em) {
    .menu:last-child {
        border-left: 1px solid #4e4e4e;
    }

    .menu:first-child {
        border-left: none;
    }

    //.menu {
    //  li:not(:last-child) {
    //    border-right: 1px solid #4e4e4e;
    //  }
    //}
}

.dropdown.menu .submenu {
    border: none;
}

.dropdown.menu .is-dropdown-submenu-parent.is-right-arrow > a::after {
    border-color: #fff transparent transparent;
}

.is-drilldown-submenu-parent > a::after {
    border-color: transparent transparent transparent #fff;
}

.js-drilldown-back::before {
    border-color: transparent #fff transparent transparent;
}
