// Constants
$myColor: seagreen;

label>input[type="radio"] {
    margin-right: 5px;
}

img.ip-logo {
    width: 100%;
    max-width: 400px;
}

div#userInfo {
    display: inline-block;
    max-width: 195px;
}

.info {
    @include callout($medium-gray);
}

.input-group-rounded {
    .input-group-field {
        border-radius: 5000px 0 0 5000px;
        padding-left: 1rem;
    }

    .input-group-button .button {
        border-radius:  0 5000px 5000px 0;
        font-size: 1rem;
    }
}

div.info dl {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
}

div.info dl dd, div.info dl dt {
    margin-bottom: 0;
    overflow-wrap: break-word;
}

figure.flag {
    margin: 0;
}

img.flag {
    margin-top: -10px;
    margin-bottom: -10px;
}

div.required-documents ul {
    font-size: 0.8rem;
}

div.required-documents ul li.required {
    color: $myColor;
}

table#actionsDue {
    thead {
        tr {
            th {
                padding-right: 0;
                vertical-align: text-top;
                span {
                    font-size: 0.7rem;
                }
            }
        }
    }

    tbody {
        tr {
            &.action-due-row {
                button {
                    padding-right: 0.5rem;
                    padding-left: 0.5rem;
                    margin-right: 0.5rem;
                }
            }
            td {
                padding-right: 0;
                input[type="date"], input.date {
                    width: 150px;
                }
            }
        }
    }
}

button#actionsDueAddRow {
    margin: 0;
}

.busy-spinner-blocker {
    z-index: 9990;
    height: 100%;
    width: 100%;
    background: #000000;
    opacity: 0.1;
    left: 0;
    top: 0;
    position: fixed;
}

.busy-spinner {
    z-index: 9991;
    position: fixed;
    left: 50%;
    top: 200px;
    margin: auto auto;

    .fa-spinner {
        text-align: center;
        display: inline-block;
        font-size: 72px;
        margin-top: 50px;
    }

}


.hasDatepicker::-ms-clear {
    display: none;
}

dd.epa-field {
    color: #008000;
}

.inventors-table {
    text-align: left;
    margin-left: 20em;
    border: 1px solid #ddd !important;
    border-collapse: separate;

    & tr td, tr th {
        padding: 0 .5em !important;
    }
}

.ratings-table {
    margin-bottom: 0em;
    border: 1px solid #ddd !important;
    border-collapse: separate;

    & tr{
        vertical-align: top;
    }
}

.currency-wrap{
	position:relative;
}

.currency-code{
	position:absolute;
	left:8px;
	top:8px;
}

.text-currency{
	padding:10px 20px;
}
