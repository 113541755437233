// Styles to make Aurelia Dialog look nice
// They embed css into bottom of head section of index.html, so !important needed to override their settings.
ux-dialog-overlay.active {
  background-color: black;
  opacity: .5 !important;
  z-index: 1050 !important;
}

ux-dialog-container {
  z-index: 1051 !important;
}

@media (min-width: 768px) {
  ux-dialog {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }

  ux-dialog.large {
    min-width: 600px !important;
    margin: 30px auto !important;
    max-width: 80% !important;
  }
}

.dialog-header-content {
  font-size: 18px;
  font-weight: 500;
}

ux-dialog > ux-dialog-header button {
  color: #333;
  padding: 6px 12px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background: #fff none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px 0 5px 5px;
}

ux-dialog > ux-dialog-header button:disabled {
  cursor: default;
  opacity: .45;
}

ux-dialog button.primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
//https://github.com/aurelia/dialog/issues/91
ux-dialog.medium {
  min-width: 450px !important;
  max-width: 60% !important;
}

ux-dialog.extra-large {
  min-width: 932px !important;
  max-width: 932px !important;
}
