
div.about {
  &.manifest {
    display: flex;
    justify-content: space-between;
    width: 400px;

    table {
      width: 45%;
    }
  }

  &.callout {
    font-size: 16px;
    text-align: center;
  }

  &.subtitle {
    color: #777;
    font-size: 0.75rem;
    text-align: center;
    margin-top: -15px;
    margin-bottom: 0;
  }

}
