.historyQuickFilter {
  width: 50%;
  max-width: 400px;
}

.submissionGrid {
  width: 100%;

  #centerRow {
//    !important flag is needed here for the ag-grid to work properly. If removed the grid doesn't show the data properly.
    height: 500px !important;
  }
}

button.ag-paging-button {
   @include button();

    &[disabled] {
        @include button-disabled();
    }
}
