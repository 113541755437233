
table.file-table tr.au-enter {
  opacity: 0 !important;
}

table.file-table tr.au-enter-active {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
}

table.file-table tr.au-leave-active {
  background-color: #FFFF99;
  -webkit-animation: fadeOut 2s;
  animation: fadeOut 2s;
}

//$row.css('background-color', '#FFFF99');
//$row.fadeOut(1000, function () {
//  $row.remove();
//});

/* CSS3-Animations */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none
  }
}

