#amendedFamilyGrid, #inventorsGrid {
  width: 100%;
  margin-bottom: 10px;


  #centerRow {
    //    !important flag is needed here for the ag-grid to work properly. If removed the grid doesn't show the data properly.
    height: 200px !important;
  }

  .ag-paging-panel {
    // this is needed to hide the pagination section with previous and next button
    display: none !important;
  }
}

.amendedFamilyShowLabel {
  font-size: 0.8rem;
}

.docket-family, .inventors-link {
  font-size: 0.8rem;
  padding-left: 8px;
}



