@charset "UTF-8";

// Pure css imports must be at the top or they are ignored.  They generate extra http request, so avoid if possible.
@import "foundation";
@import "fa-svg-with-js";

// Our imports
@import "aurelia-dialog-style";
@import "submission-grid";
@import "amended-family-grid";
@import "submission-form";
@import "upload";
@import "upload-animation";
@import "about";
@import "support";
@import "login";

// These are SASS examples of a user defined mixin and using the mixin in a style definition.

//@mixin text3d($color) {
//  color: $color;
//  text-shadow: 1px 1px 0 darken($color, 5%), 2px 2px 0 darken($color, 10%), 3px 3px 0 darken($color, 15%), 4px 4px 0 darken($color, 20%), 4px 4px 2px #000;
//}
//
//h1.three-d {
//  font-size: 24pt;
//  @include text3d(#0982c1);
//}

// Constants
//$myColor: seagreen;

// Our 'global' css

body {
    margin: 0;
    overflow-y: scroll;
}

nav {
    margin-bottom: 10px;

    .spinner {
        width: 50px;
        margin-top: 6px;
        color: white;
    }
}

.splash {
    text-align: center;
    margin: 10% 0 0 0;
    box-sizing: border-box;

    .message {
        font-size: 72px;
        line-height: 72px;
        text-shadow: rgba(0, 0, 0, 0.5) 0 0 15px;
        text-transform: uppercase;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .fa-spinner {
        text-align: center;
        display: inline-block;
        font-size: 72px;
        margin-top: 50px;
    }
}

.loading {
    text-align: center;

    .message {
        font-size: 24px;
        line-height: 24px;
    }

    .fa-spinner {
        text-align: center;
        display: inline-block;
        font-size: 24px;
        margin-top: 20px;
    }
}

label, label.inline {
    padding-right: 7px;
}

label.required, span.required, legend.required {
    padding-right: 7px;
    background: url(/dist/images/required.png) no-repeat right;
}

/*Required indicator for left aligned text */
label.required-inline, span.required-inline{
    display: inline;
    padding-right: 7px;
    background: url(/dist/images/required.png) no-repeat right;
}

textarea {
    height: 6rem;
}

button, .button {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

button.radius, .button.radius {
    border-radius: 3px;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    font-size: .875rem;
}

div.primary.row {

    div.columns {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

footer {
    padding-bottom: 5rem;

    ul {
        li {
            font-size: 0.7rem;
        }
    }

    hr {
        max-width: none;
    }
}

//:required {
//  box-shadow: 0 0 3px rgba(255, 0, 0, 0.5);
//}

//:required:focus {
//  box-shadow: 0  0 3px rgba(255,0,0,0.5);
//}

// Foundation overrides

select {
    height: auto;
}

select[size], select[multiple] {
    background-image: none;
}

input[type="checkbox"], input[type="radio"] {
    margin-top: 10px;
}

.alert-box {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

span.form-error, small.form-error {
    display: block;
    font-size: .75rem;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: -16px;
    padding-top: .375rem;
    padding-right: .5625rem;
    padding-left: .5625rem;
    padding-bottom: .5625rem;
    background: #f04124;
    color: #fff;
}

.font-weight-normal{
    font-weight: normal;
}

.font-weight-bold{
    font-weight: bold;
}

// End  Foundation overrides
