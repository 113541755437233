
div.login {
  max-width: 600px;
  margin: 25px;

  &.modal {
    width: 450px;
  }
}

.login div.container {
  max-width: 600px;
}
