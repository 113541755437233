
div.support {

  width: 500px;

  .alert-box {
    font-size: 16px;
    text-align: center;
  }

}
